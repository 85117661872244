import { Show, TableSkeletonLoader, Tooltip } from '@nirvana/ui-kit';
import { Header, Table as TableType, flexRender } from '@tanstack/react-table';
import clsx from 'clsx';
import React from 'react';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { HiOutlineInformationCircle } from 'react-icons/hi';

/**
 * Table component, it needs to be used with tanstack/react-table (not react-table-v7)
 */

const HeaderContent = ({ header }: { header: Header<any, unknown> }) => {
  if (header.isPlaceholder) {
    return null;
  }

  const { columnDef } = header.column;
  const { meta } = columnDef;

  const content = flexRender(
    columnDef.header,
    header.getContext(),
  ) as JSX.Element;

  return meta?.tooltipText ? (
    <Tooltip title={<div className="max-w-xs">{meta.tooltipText}</div>} arrow>
      <div className="flex items-center">
        {content}
        <HiOutlineInformationCircle className="inline-block ml-1 text-primary-dark" />
      </div>
    </Tooltip>
  ) : (
    content
  );
};

export function Table<T>({
  table,
  loading = false,
}: {
  table: TableType<T>;
  loading: boolean;
}) {
  return (
    <table className="w-full">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="px-4 py-2 text-xs font-normal text-left bg-primary-extraLight text-secondary-dark"
              >
                <button
                  onClick={header.column.getToggleSortingHandler()}
                  className="flex items-center gap-2"
                >
                  <HeaderContent header={header} />
                  <Show when={header.column.getCanSort()}>
                    {{
                      asc: <FaSortUp />,
                      desc: <FaSortDown />,
                    }[header.column.getIsSorted() as string] ?? <FaSort />}
                  </Show>
                </button>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="divide-y">
        {loading ? (
          <TableSkeletonLoader
            columns={table.getAllColumns().length}
            rows={6}
          />
        ) : (
          <>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={clsx('text-xs font-normal text-text-primary', {
                  'bg-secondary-tint2': row.getIsSelected(),
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="p-4">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </>
        )}
      </tbody>
      <tfoot>
        {table.getFooterGroups().map((footerGroup) => (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.footer,
                      header.getContext(),
                    )}
              </th>
            ))}
          </tr>
        ))}
      </tfoot>
    </table>
  );
}
