import { differenceInHours } from 'date-fns';
import addHours from 'date-fns/addHours';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { useMemo, useCallback } from 'react';
import useLocalStorage from 'src/hooks/use-local-storage';

type CollectClaimsFeedback = {
  id: string;
  expiresAt: string;
};

const HOURS_BETWEEN_FEEDBACK_MODALS = 24;

type RecentlyViewedFeedbackModals = Record<string, { lastViewedAt: string }>;

const defaultCollectClaimFeedbackValue = {
  id: '',
  expiresAt: '',
};

export const useCollectClaimsFeedback = () => {
  const [collectClaimsFeedback, setCollectClaimsFeedback] =
    useLocalStorage<CollectClaimsFeedback>(
      'collectClaimsFeedbackCandidate',
      defaultCollectClaimFeedbackValue,
    );

  const [recentlyViewedFeedbackModals, setRecentlyViewedFeedbackModals] =
    useLocalStorage<RecentlyViewedFeedbackModals>(
      'recentlyViewedFeedbackModals',
      {},
    );

  const resetCollectClaimsFeedback = useCallback(() => {
    setCollectClaimsFeedback(defaultCollectClaimFeedbackValue);
  }, [setCollectClaimsFeedback]);

  const enableFeedbackModalForClaim = useCallback(
    (claimId: string) => {
      setCollectClaimsFeedback({
        id: claimId,
        expiresAt: addHours(new Date(), 1).toISOString(),
      });
    },
    [setCollectClaimsFeedback],
  );

  const shouldShowFeedbackModal = useMemo(() => {
    const hasPendingFeedbackWithinTimeframe =
      Boolean(collectClaimsFeedback.expiresAt) &&
      differenceInSeconds(
        new Date(collectClaimsFeedback.expiresAt),
        new Date(),
      ) > 0;

    if (!hasPendingFeedbackWithinTimeframe) return false;

    const isInRecentlyViewedFeedbackModals =
      recentlyViewedFeedbackModals[collectClaimsFeedback.id];

    const notShownRecently =
      !isInRecentlyViewedFeedbackModals ||
      (isInRecentlyViewedFeedbackModals &&
        differenceInHours(
          new Date(collectClaimsFeedback.expiresAt),
          new Date(),
        ) > HOURS_BETWEEN_FEEDBACK_MODALS);
    return hasPendingFeedbackWithinTimeframe && notShownRecently;
  }, [
    collectClaimsFeedback.expiresAt,
    collectClaimsFeedback.id,
    recentlyViewedFeedbackModals,
  ]);

  const markAsViewed = useCallback(() => {
    setRecentlyViewedFeedbackModals((prev) => ({
      ...prev,
      [collectClaimsFeedback.id]: { lastViewedAt: new Date().toISOString() },
    }));
  }, [collectClaimsFeedback.id, setRecentlyViewedFeedbackModals]);

  const claimId = collectClaimsFeedback.id;
  return {
    resetCollectClaimsFeedback,
    enableFeedbackModalForClaim,
    shouldShowFeedbackModal,
    claimId,
    markAsViewed,
  };
};
