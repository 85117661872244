import { SnackbarOrigin, VariantType } from 'notistack';

export const snackBarOptions = (variant: VariantType) => {
  return {
    anchorOrigin: {
      vertical: 'bottom' as SnackbarOrigin['vertical'],
      horizontal: 'center' as SnackbarOrigin['horizontal'],
    },
    variant,
  };
};
