import { useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { HiChevronRight, HiOutlineExclamation } from 'react-icons/hi';
import {
  useGetBasicRecommendationQuery,
  useGetBasicScoresQuery,
} from 'src/types/graphql-types';
import { range, Show, Switch } from '@nirvana/ui-kit';
import { Feature, useFeatureEnabled } from 'src/helpers/feature-flags';
import { analytics, Dates } from 'src/helpers';
import subMonths from 'date-fns/subMonths';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import IconCheck from 'src/assets/icons/check.svg?react';
import { useAnalytics } from 'src/helpers/analytics';

export default function BSRecommendations({
  discount,
  alertScoreCategories,
}: {
  discount: number;
  alertScoreCategories: any;
}) {
  const { reportId = '' } = useParams();
  const { trackEvent } = useAnalytics();
  const shouldShowBookingButton = useFeatureEnabled(
    Feature.SHOW_BOOKING_BUTTON,
  );
  const { data, loading } = useGetBasicRecommendationQuery({
    variables: { reportId },
  });
  const [monthsWithGoodBasicScores, setMonthsWithGoodBasicScores] = useState(0);

  const MONTHS_TO_FETCH = 12;

  const fromTimestamp = useMemo(() => {
    const startMonth = startOfMonth(subMonths(new Date(), MONTHS_TO_FETCH));
    return Dates.formatISOUTC(startMonth);
  }, []);

  const toTimestamp = useMemo(() => {
    const lastMonth = endOfMonth(subMonths(new Date(), 1));
    return Dates.formatISOUTC(lastMonth);
  }, []);

  useGetBasicScoresQuery({
    variables: { reportId, fromTimestamp, toTimestamp },
    onCompleted: ({ fleetSafetyReport }) => {
      if (!fleetSafetyReport) {
        return;
      }

      const monthsWithGoodBasicScores = fleetSafetyReport.basicScores.reduce(
        (acc, basicScoresForMonth) => {
          // We consider anything about trend as over the threshold
          // and trend is 35% lower than the threshold for alert
          // based on https://coda.io/d/Engineering-Wiki_dmBWLoxkuyN/BASICs_sunbA#_ludUP
          const hasScoreOverThreshold = basicScoresForMonth.scores.some(
            (score) => (score.percentile || 0) > score.threshold - 35,
          );
          if (!acc.shouldStop && !hasScoreOverThreshold) {
            return {
              count: acc.count + 1,
              shouldStop: false,
            };
          }

          return {
            count: acc.count,
            shouldStop: true,
          };
        },
        {
          count: 0,
          shouldStop: false,
        },
      );

      setMonthsWithGoodBasicScores(monthsWithGoodBasicScores.count);
    },
  });

  // wrapped click to send event SafetyManagerBookingClicked
  const wrappedBookingClick = () => {
    trackEvent({
      event: analytics.EventTrack.SafetyManagerBookingClicked,
    });
    const bookingUrl = import.meta.env.VITE_CALENDLY_BOOKING_URL;
    if (bookingUrl) {
      window.open(bookingUrl, '_blank', 'noopener, noreferrer');
    }
  };

  // Check if any category is in alert(red)
  const categoryOnAlert = useMemo(
    () =>
      alertScoreCategories.some(
        (category: { percentileOver: number }) => category.percentileOver >= 1,
      ),
    [alertScoreCategories],
  );

  const reasons = useMemo(() => {
    const recommendations =
      data?.fleetSafetyReport?.recommendations?.edges ?? [];
    const sections =
      recommendations.map((record) => record.node.sections).flat() ?? [];
    const sortedReasons = sections
      .map((section) => {
        const reasonsCopy = [...(section.reasons ?? [])];
        return reasonsCopy.sort(
          (a, b) => (b.weightage ?? 0) - (a.weightage ?? 0),
        )[0];
      })
      .filter(Boolean);
    const reasonsPerCategory: { [key: string]: any[] } = {};
    // Initialize reasonsPerCategory with sorted alertScoreCategories via (percentile - threshold)
    alertScoreCategories
      .sort(
        (a: { percentileOver: number }, b: { percentileOver: number }) =>
          b.percentileOver - a.percentileOver,
      )
      .forEach(
        (category: { name: string | number; percentileOver: number }) => {
          reasonsPerCategory[category.name] = [];
        },
      );
    // only the reason with most weightage per filtered BASIC Category
    sortedReasons.forEach((reason) => {
      const category = reason?.basicCategory;
      if (
        category &&
        category in reasonsPerCategory &&
        reasonsPerCategory[category]?.length === 0
      ) {
        reasonsPerCategory[category].push(reason);
      }
    });
    return Object.values(reasonsPerCategory).flat().slice(0, 2);
  }, [data?.fleetSafetyReport?.recommendations.edges, alertScoreCategories]);
  if (!loading && reasons.length === 0 && monthsWithGoodBasicScores > 0) {
    return (
      <div
        className="flex flex-col justify-between p-4 rounded-md bg-text-lightestGreen"
        data-testid="good-streak"
      >
        <div>
          <div className="flex items-center space-x-2 space-y-2">
            <IconCheck />
            <div className="flex flex-col">
              <strong className="text-5xl text-success-tint1">
                {monthsWithGoodBasicScores}
              </strong>
              <span className="text-text-hint">month streak</span>
            </div>
          </div>
          <div className="my-4 text-lg">
            Keep up the good work! You’ve maintained outstanding BASIC scores
            for{' '}
            {MONTHS_TO_FETCH === monthsWithGoodBasicScores ? 'at least ' : ' '}
            <strong>{monthsWithGoodBasicScores} months.</strong>
          </div>
        </div>
        <Show when={shouldShowBookingButton}>
          <div>
            <p className="pt-8">
              Want tips on how to keep the streak going? Book time with your
              fleet safety manager.
            </p>
            <Button
              variant="text"
              className="p-0 mt-4"
              endIcon={<HiChevronRight />}
              onClick={wrappedBookingClick}
            >
              Book
            </Button>
          </div>
        </Show>
      </div>
    );
  }

  return (
    <div className="flex flex-col p-4 border rounded-md">
      <p className="mb-4 font-bold text-text-primary">
        Factors that impact your score
      </p>

      <Switch>
        <Switch.Match when={loading}>
          {range(2).map((item) => (
            <div
              key={item}
              className="flex items-start p-3 mb-4 border rounded-md"
            >
              <div className="w-12 h-12 mr-4 bg-gray-100 rounded-md animate-pulse" />
              <div className="flex-1 space-y-2">
                <div className="w-full h-6 bg-gray-100 rounded animate-pulse" />
                <div className="w-full h-10 bg-gray-100 rounded animate-pulse" />
              </div>
            </div>
          ))}
        </Switch.Match>

        <Switch.Match when={reasons.length > 0}>
          {reasons.map((reason, idx) => (
            <div
              key={idx}
              data-testid="recommendation-item"
              className="flex items-start p-3 mb-4 border rounded-md"
            >
              <div className="p-1 mr-4 text-2xl rounded bg-error-main/10 text-error-main">
                <HiOutlineExclamation />
              </div>
              <div>
                <p className="mb-1 font-bold">{reason.basicCategory}</p>
                <p>
                  <Link
                    to={reason.link ?? `/${reportId}/insights/violations`}
                    data-testid="violation-link"
                    className="font-bold underline text-primary-main"
                  >
                    {reason.violationCount} {reason.violationGroup} Violations
                  </Link>{' '}
                  impact {(reason.weightage ?? 0).toFixed()}% of your{' '}
                  {reason.basicCategory} score
                </p>
              </div>
            </div>
          ))}
        </Switch.Match>
      </Switch>

      <div className="flex-1" />

      <Show when={discount >= 2}>
        <div className="flex flex-col items-center p-4 py-3 mb-4 space-y-1 rounded-md bg-success-extraLight/50">
          <p className="font-normal">
            Improving your BASIC scores could reduce your premium by{' '}
            <b>{discount}%</b>
          </p>
          <Show
            when={categoryOnAlert && shouldShowBookingButton}
            fallback={
              <Link to={`/${reportId}/recommendations`}>
                <Button endIcon={<HiChevronRight />}>
                  View Recommendations
                </Button>
              </Link>
            }
          >
            <Button
              variant="contained"
              endIcon={<HiChevronRight />}
              onClick={wrappedBookingClick}
            >
              Book Time with a Nirvana Safety Manager
            </Button>
          </Show>
        </div>
      </Show>
    </div>
  );
}
