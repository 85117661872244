import { analytics } from 'src/helpers';
import useSnackbar from 'src/hooks/useSnackbar';
import {
  FnolNoticeType,
  LineOfBusiness,
  useCreateFnolMutation,
} from 'src/types/graphql-types';
import { ApolloError } from '@apollo/client';
import { useAnalytics } from 'src/helpers/analytics';
import { useState } from 'react';
import { Attachment, ClaimType, NewClaimForm } from '@nirvana/ui-kit';
import format from 'date-fns/format';
import { NewClaimProvider } from './NewClaimProvider';

const noticeTypes = {
  initiateClaim: FnolNoticeType.Claim,
  onlyReporting: FnolNoticeType.Report,
};

const lineOfBusiness = {
  CoverageAutoLiability: LineOfBusiness.AutoLiability,
  CoverageAutoPhysicalDamage: LineOfBusiness.AutoLiability,
  CoverageGeneralLiability: LineOfBusiness.GeneralLiability,
  CoverageMotorTruckCargo: LineOfBusiness.MotorTruckCargo,
};

export const NewClaim = () => {
  const { trackEvent } = useAnalytics();
  const pushSnackbar = useSnackbar();
  const [submittedSuccessfully, setSubmittedSuccessfully] =
    useState<boolean>(false);

  const onCreateError = (error: ApolloError) => {
    // TODO: (RAFA) Handle gql error codes (not matching strings)
    if (
      error.graphQLErrors &&
      error.graphQLErrors.length &&
      error.graphQLErrors[0].message.includes('failed to fetch policy')
    ) {
      pushSnackbar(
        'Error submitting claim',
        'Policy Number is not valid or does not exist',
        {
          variant: 'error',
        },
      );
      return;
    }
    pushSnackbar('Error submitting claim', 'Something went wrong', {
      variant: 'error',
    });
  };

  const [createFNOL, { loading }] = useCreateFnolMutation({
    onCompleted: () => {
      setSubmittedSuccessfully(true);
    },
    onError: onCreateError,
  });

  const onSubmit = (data: ClaimType) => {
    createFNOL({
      variables: {
        policyNumber: data.policyNumber,
        description: data.description,
        noticeType: noticeTypes[data.noticeType],
        lossLocation: data.lossLocation,
        lossState: data.lossState,
        lossDate: format(data.lossDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        injuriesInvolved: data.injureds === 'yes',
        police: {
          agencyName: data.police.agencyName,
          reportNumber: data.police.reportNumber,
        },
        reporter: {
          firstName: data.reporter.firstName,
          lastName: data.reporter.lastName,
          phone: data.reporter.phone,
          email: data.reporter.email,
        },
        insuredVehicles: data.insuredVehicleVins.map((vin: string) => ({
          vin: vin,
        })),
        otherVehicles: data.otherVehicleVins.map((plate: string) => ({
          registrationNumber: plate,
        })),
        insuredName: data.insuredName,
        lineOfBusiness: lineOfBusiness[data.lineOfBusiness],
        attachmentKeys: data.attachments.map(
          (att: Attachment) => att.key || '',
        ),
      },
    });
    trackEvent({
      event: analytics.EventTrack.FNOLFormSubmissions,
    });
  };
  const onInvalidForm = () => {
    pushSnackbar('Cannot submit form', "You're missing some fields", {
      variant: 'error',
    });
  };

  return (
    <div className="flex flex-col bg-gray-50">
      <NewClaimProvider>
        <NewClaimForm
          onSubmit={onSubmit}
          onInvalidForm={onInvalidForm}
          submittedSuccessfully={submittedSuccessfully}
          isSubmittingFNOL={loading}
          onCreateError={onCreateError}
          storeFnolMutation={useCreateFnolMutation}
        />
      </NewClaimProvider>
    </div>
  );
};
