import { Dialog } from '@nirvana/ui-kit';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { ExplainabilityRecommendation } from 'src/types/graphql-types';
import { IconMapping } from '../constants/explainabilityIcons';

type Props = {
  onClose: () => void;
  open: boolean;
  recommendation: ExplainabilityRecommendation;
};

const ScoreRecommendationModal = ({ onClose, open, recommendation }: Props) => {
  type RecommendationContent = {
    explanation: string[];
    recommendations: string[];
  };

  const recommendationsContent: { [key: string]: RecommendationContent } = {
    Congestion: {
      explanation: [
        'Congestion is calculated using telematics data, identifying heavy stop-and-go behavior and high-traffic areas.',
        "These patterns increase exposure to accidents and other risks, which are reflected in the fleet's score.",
      ],
      recommendations: [
        'Conduct route planning to minimize exposure to high-congestion zones by scheduling travel during off-peak hours where possible.',
        'Use telematics insights to identify frequent bottlenecks and adjust schedules or routes to avoid these areas.',
        'Provide coaching on safe driving in stop-and-go traffic, such as maintaining a steady speed, increasing following distance, and avoiding abrupt stops.',
      ],
    },
    'Local Driving': {
      explanation: [
        'Local driving is identified using telematics data, focusing on average speeds below 50 mph. These conditions highlight risks such as frequent stops, intersections and more interactions with pedestrians or other vehicles.',
      ],
      recommendations: [
        'Encourage drivers to use defensive techniques, such as scanning for hazards and maintaining awareness of intersections and pedestrian zones.',
        'Implement route optimization to reduce unnecessary local trips and avoid high-risk areas.',
        'Provide training on managing frequent stops and navigating complex traffic patterns safely.',
      ],
    },
    'Harsh Braking': {
      explanation: [
        'Harsh braking occurs when a driver applies sudden, intense brake pressure, identified through telematics data registering above 0.2 g-forces.',
        "High instances can reflect reactive rather than proactive driving, which increases the fleet's score and safety risks.",
      ],
      recommendations: [
        'Encourage drivers to maintain a safe following distance—at least one second for every 10 mph of speed.',
        'Promote smoother driving habits by emphasizing anticipation of traffic patterns and gradual deceleration.',
        'Use driver coaching tools or real-time alerts to identify and correct patterns of harsh braking.',
      ],
    },
  };

  if (recommendation == null) {
    return null;
  }

  const content = recommendationsContent[recommendation.category];
  return (
    <Dialog open={open} maxWidth="xs">
      <div className="relative flex flex-col items-left px4 pt-2 pb-6 text-left max-w-6">
        <IconButton
          aria-label="close"
          className="absolute top-0 right-0 text-gray-500"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <div className="flex items-center mb-5">
          <div className="p-1 mr-4 text-2xl rounded">
            {IconMapping(recommendation.percentile)}
          </div>
          <h2 className="text-xl font-semibold text-text-primary">
            {recommendation.category}
          </h2>
        </div>
        <div className="mb-4">
          {content.explanation.map((explanation) => (
            <div key={explanation} className="mb-2">
              {explanation}
            </div>
          ))}
        </div>
        <div className="mb-4 font-semibold">Recommendations:</div>
        <ul className="list-disc ml-5">
          {content.recommendations.map((recommendation) => (
            <li key={recommendation} className="mb-2">
              {recommendation}
            </li>
          ))}
        </ul>
      </div>
    </Dialog>
  );
};

export default ScoreRecommendationModal;
