import { useMemo } from 'react';
import { Button } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import {
  HiChevronRight,
  HiOutlineChartSquareBar,
  HiOutlineInformationCircle,
} from 'react-icons/hi';
import { endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { BasicScoresChart, getFormattedDate, Tooltip } from '@nirvana/ui-kit';

import { Dates } from 'src/helpers';
import { useGetBasicScoresQuery } from 'src/types/graphql-types';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import { basicChartColors } from '../constants/rating';
import { basicGraphLabelMap } from '../constants/basic-graph-label-map';
import BSRecommendations from './bs-recommendations';
import AccidentSummary from './accident-summary';

const EXCLUDED_CATEGORIES_FROM_VIOLATIONS_PAGE = ['Crash Indicator'];

export default function BasicScores() {
  const { reportId = '' } = useParams();
  const navigate = useNavigate();

  const fromTimestamp = useMemo(() => {
    const startMonth = startOfMonth(subMonths(new Date(), 7));
    return Dates.formatISOUTC(startMonth);
  }, []);

  const toTimestamp = useMemo(() => {
    const lastMonth = endOfMonth(subMonths(new Date(), 1));
    return Dates.formatISOUTC(lastMonth);
  }, []);

  const { data } = useGetBasicScoresQuery({
    variables: { reportId, fromTimestamp, toTimestamp },
  });

  const basicScores = useMemo(() => {
    const recentScores = data?.fleetSafetyReport?.basicScores[0]?.scores ?? [];
    const prevMonthScores =
      data?.fleetSafetyReport?.basicScores[1]?.scores ?? [];

    return recentScores.map((score) => ({
      ...score,
      percentile: score.percentile ?? 0,
      measure:
        score.measure && !isNaN(score.measure) ? score.measure.toFixed(3) : 0,
      changeFromLastMonth:
        (prevMonthScores.find(({ name }) => name === score.name)?.percentile ??
          0) - (score.percentile ?? 0),
      color:
        score.percentile && score.percentile >= score.threshold
          ? basicChartColors.error
          : basicChartColors.success,
      cursor: !EXCLUDED_CATEGORIES_FROM_VIOLATIONS_PAGE.includes(score.name)
        ? 'pointer'
        : undefined,
    }));
  }, [data]);

  // alertScoreCategories contain BASIC Categories where percentile is in the trend threshold upwards
  // trend threshold is alert threshold - 35%
  const alertScoreCategories = useMemo(() => {
    return basicScores
      .filter(
        (score) =>
          score.percentile &&
          score.percentile > Math.max(score.threshold - 35, 0),
      )
      .map((score) => ({
        name: score.name,
        percentileOver: 100 + (score.percentile ?? 0) - (score.threshold ?? 0),
      }));
  }, [basicScores]);

  const scoreDate = useMemo(() => {
    const date = data?.fleetSafetyReport?.basicScores[0]?.scoreDate;
    return date ? getFormattedDate(date, 'MM/dd/yyyy') : '';
  }, [data]);

  const navigateToViolations = (e?: CategoricalChartState) => {
    if (!e) {
      return;
    }
    if (
      EXCLUDED_CATEGORIES_FROM_VIOLATIONS_PAGE.includes(e.activeLabel ?? '')
    ) {
      return;
    }
    const route = `/${reportId}/insights/violations?category=${e.activeLabel?.replaceAll(
      ' ',
      '',
    )}`;

    navigate(route);
  };

  return (
    <div className="mb-6 bg-white rounded-lg shadow">
      <div className="flex flex-wrap items-center p-4 space-x-4 border-b">
        <div className="p-1 text-xl rounded text-primary-main bg-primary-extraLight">
          <HiOutlineChartSquareBar />
        </div>
        <span className="text-xl font-semibold text-gray-800">
          BASIC Scores
        </span>
        <Tooltip
          placement="right"
          title="Pursuant to the FAST Act of 2005, property carrier BASIC scores have been removed from public view. These scores are estimates calculated by Nirvana."
        >
          <span className="text-lg text-primary-main">
            <HiOutlineInformationCircle />
          </span>
        </Tooltip>
        <div className="flex-1" />
        <span className="mt-4 text-xs text-text-secondary">
          Most Recent FMCSA Data Release Date: {scoreDate}
        </span>
      </div>

      <div className="grid gap-4 p-4 lg:grid-cols-5">
        <div className="p-4 border rounded-md lg:col-span-3">
          <p className="mb-2 font-bold text-text-primary">
            BASIC Categories that need attention
          </p>
          <div className="mb-4 h-80">
            <BasicScoresChart
              data={basicScores}
              xField="name"
              yField="percentile"
              onBarChartClick={navigateToViolations}
              minHeight={2.5}
              customLabels={basicGraphLabelMap}
            />
          </div>
          <Link
            smooth
            to={`/${reportId}/insights/scores#basic-score`}
            className="flex items-center justify-center"
          >
            <Button endIcon={<HiChevronRight />}>View trends</Button>
          </Link>
        </div>
        <div className="lg:col-span-2">
          <BSRecommendations
            discount={data?.fleetSafetyReport?.basicScores[0]?.discount ?? 0}
            alertScoreCategories={alertScoreCategories}
          />
        </div>
        <div className="overflow-x-auto border rounded-md lg:col-span-5">
          <AccidentSummary />
        </div>
      </div>
    </div>
  );
}
