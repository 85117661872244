import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Rating } from '@material-ui/core';
import { Dialog, InputWithLabel, Show } from '@nirvana/ui-kit';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useSnackbar from 'src/hooks/useSnackbar';
import {
  FeedbackCategory,
  useClaimByIdQuery,
  useCreateClaimFeedbackMutation,
} from 'src/types/graphql-types';
import { z } from 'zod';
import { useCollectClaimsFeedback } from '../hooks/useCollectClaimsFeedback';
import { snackBarOptions } from '../constants/snackbarConfig';
import MultiSelectButtons from './MultiSelectButtons';

const MAX_RATING = 5;

const claimFeedbackSchema = z
  .object({
    rating: z.number().min(1).max(5),
    claimId: z.string(),
    category: z.nativeEnum(FeedbackCategory, {
      required_error: 'Please select a category',
    }),
    feedback: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.rating === MAX_RATING) {
        return true;
      }
      if (!data.category || data.category.length === 0) {
        return false;
      }
      return true;
    },
    {
      message: 'Please select a category',
      path: ['category'],
    },
  );

type ClaimFeedbackType = z.infer<typeof claimFeedbackSchema>;

export const ClaimRating = () => {
  const {
    claimId,
    shouldShowFeedbackModal,
    resetCollectClaimsFeedback,
    markAsViewed,
  } = useCollectClaimsFeedback();
  const { data } = useClaimByIdQuery({
    skip: !claimId,
    variables: {
      id: claimId,
    },
  });
  const { externalId, canSubmitFeedback } = data?.claimById || {};

  const [showDialog, setShowDialog] = useState(false);
  const [numericRating, setNumericRating] = useState<number | null>(null);

  const enqueueSnackbar = useSnackbar();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ClaimFeedbackType>({
    mode: 'onChange',
    resolver: zodResolver(claimFeedbackSchema),
    defaultValues: {
      claimId,
    },
  });

  // todo: move this to a onSuccess callback of the graphql query
  useEffect(() => {
    if (shouldShowFeedbackModal && canSubmitFeedback && Boolean(externalId)) {
      setShowDialog(true);
      markAsViewed();
    }
  }, [shouldShowFeedbackModal, canSubmitFeedback, markAsViewed, externalId]);

  const handleRatingChange = (
    _: React.SyntheticEvent,
    rating: number | null,
  ) => {
    if (rating === null) {
      return;
    }
    setValue('rating', rating);
    setNumericRating(rating);
    if (rating === MAX_RATING) {
      setValue('category', FeedbackCategory.OutstandingService);
    }
    setShowDialog(true);
  };

  const closeModal = () => {
    resetCollectClaimsFeedback();
    setShowDialog(false);
  };

  const [submitFeedback, { loading: savingFeedback, error: submitError }] =
    useCreateClaimFeedbackMutation();

  const handleFeedbackModalSubmit = (data: ClaimFeedbackType) => {
    submitFeedback({
      variables: {
        claimId,
        rating: data.rating,
        category: data.category,
        value: data.feedback ?? '',
      },

      onCompleted: () => {
        closeModal();
        enqueueSnackbar(
          'Thank you for your feedback!',

          'We appreciate your feedback and will use it to improve our service.',
          snackBarOptions('success'),
        );
      },

      onError: ({ graphQLErrors }) => {
        let message =
          graphQLErrors?.toString() || 'Something went wrong, please try again';
        if (message.includes('already closed and has a feedback')) {
          message =
            'You have already submitted feedback rating for this closed claim.';
        }
        enqueueSnackbar('', message, snackBarOptions('error'));
      },
    });
  };
  const showLoading = !submitError && savingFeedback;
  const category = watch('category');

  return (
    <>
      <Dialog
        open={showDialog}
        onClose={closeModal}
        // we use to force the close icon to show up
        // as dialog component has a align-items center for the title
        // that does not allow us to pass the real title here
        title={<span />}
        secondaryAction={
          <p className="max-w-xs pr-2 text-xs text-text-hint">
            Feedback is used to improve claims experience. Adjusters can't see
            your rating.
          </p>
        }
        primaryAction={
          <Button
            type="submit"
            variant="contained"
            onClick={
              showLoading ? () => {} : handleSubmit(handleFeedbackModalSubmit)
            }
          >
            {showLoading ? 'Saving...' : 'Submit'}
          </Button>
        }
      >
        <div className="md:w-96">
          <h2
            className="-mt-6 text-lg text-center"
            data-testid="feedback-dialog-title"
          >
            How's your experience with the claim
            <span className="font-medium"> ({externalId})</span> you just
            viewed?
          </h2>

          <form onSubmit={handleSubmit(handleFeedbackModalSubmit)}>
            <div className="flex justify-center my-6">
              <Rating
                size="large"
                value={numericRating}
                onChange={handleRatingChange}
              />
            </div>
            <Show when={numericRating !== null && numericRating < MAX_RATING}>
              <MultiSelectButtons
                testId="what-could-be-better"
                label="What could be better?"
                options={[
                  {
                    value: FeedbackCategory.FasterClaimResolution,
                    label: 'Faster resolution',
                  },
                  {
                    value: FeedbackCategory.FrequentAdjusterCommunication,
                    label: 'Better communication',
                  },

                  {
                    value: FeedbackCategory.OfferFairerCompensation,
                    label: 'Fairer Compensation',
                  },
                  {
                    value: FeedbackCategory.ProvideBetterSupport,
                    label: 'More support',
                  },
                  {
                    value: FeedbackCategory.RequireFewerDocuments,
                    label: 'Fewer documents',
                  },
                  {
                    value: FeedbackCategory.Other,
                    label: 'Other',
                  },
                ]}
                error={errors.category?.message}
                onChange={(selected) => setValue('category', selected)}
              />
            </Show>
            <Show
              when={
                numericRating !== null &&
                numericRating < MAX_RATING &&
                category === FeedbackCategory.Other
              }
            >
              <InputWithLabel
                label="Please tell us more!"
                placeholder="Please provide a brief explanation to help us improve your experience."
                {...register('feedback')}
                multiline
                rows={5}
                data-testid="reason-input"
                formControlProps={{
                  className: 'w-full mt-8',
                }}
                error={!!errors.feedback}
                helperText={errors.feedback?.message}
              />
            </Show>
          </form>
        </div>
      </Dialog>
    </>
  );
};
