import { ChipProps } from '@material-ui/core';
import { Chip } from '@nirvana/ui-kit';
import { ClaimStatus, FnolStatus } from 'src/types/graphql-types';
import log from 'src/utils/logger';

export const StatusChip = ({
  status,
}: {
  status: ClaimStatus | FnolStatus;
  size?: ChipProps['size'];
}) => {
  switch (status) {
    case ClaimStatus.Open:
      return <Chip color="success" label="Open" />;

    case FnolStatus.Processed:
      return <Chip color="success" label="Claim Created" />;

    case FnolStatus.Sent:
      return <Chip color="success" label="Notice Sent" />;

    case ClaimStatus.Closed:
    case FnolStatus.Declined:
    case FnolStatus.Invalid:
      return <Chip color="grey" label="Closed" />;
    case ClaimStatus.Reopen:
      return <Chip color="info" label="Reopened" />;
    default:
      log(`Unknown status: ${status}`, 'info');
      return <Chip color="info" label={status} />;
  }
};
