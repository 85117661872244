import { useContext, useMemo } from 'react';
import { Button, Divider } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { format } from 'date-fns';
import { Show } from '@nirvana/ui-kit';
import { Attachment, NewClaimContext } from '../contexts/newClaim';
import {
  policyCoveragesMapping,
  noticeTypeMapping,
  usStatesMapping,
  Step,
} from '../constants';
import { FileListItem } from './FileListItem';

type ReviewAndSubmitProps = {
  formType?: 'insured' | 'support';
  onEdit: (step: Step) => void;
};

export const ReviewAndSubmit = ({
  formType = 'insured',
  onEdit,
}: ReviewAndSubmitProps) => {
  const { getValues, rejectedAttachments, watch } = useContext(NewClaimContext);
  const form = getValues();
  const attachments = watch('attachments');

  const insuredVehicleVins = useMemo(() => {
    return form.insuredVehicleVins.filter((vin) => vin.length > 0);
  }, [form.insuredVehicleVins]);

  const otherVehicleVins = useMemo(() => {
    return form.otherVehicleVins.filter((vin: string) => vin.length > 0);
  }, [form.otherVehicleVins]);

  return (
    <div className="flex flex-col">
      <div>
        <h3 className="text-3xl font-bold">Review & Submit</h3>
        <p className="mt-2 text-xl text-text-hint">
          Please ensure all information is accurate before sumitting.
        </p>
      </div>

      <div className="flex flex-col w-full p-8 mt-8 bg-white rounded-lg shadow">
        <div className="flex flex-row items-center">
          <h5 className="text-xl font-bold">Reporter Information</h5>
          <Button
            data-testid="edit-reporter-information-button"
            onClick={() => onEdit('reporter-information')}
          >
            <Edit className="mr-2" /> Edit
          </Button>
        </div>
        <Divider className="py-2" />

        <div className="mt-4">
          <div className="flex">
            <p className="flex-1 font-semibold max-w-72">Name</p>
            <p className="font-normal">
              {!(form.reporter.firstName || form.reporter.lastName)
                ? '-'
                : `${form.reporter.firstName} ${form.reporter.lastName}`}
            </p>
          </div>

          <div className="flex mt-4">
            <p className="flex-1 font-semibold max-w-72">Email</p>
            <p className="font-normal">{form.reporter.email || '-'}</p>
          </div>

          <div className="flex mt-4">
            <p className="flex-1 font-semibold max-w-72">Phone</p>
            <p className="font-normal">{form.reporter.phone || '-'}</p>
          </div>

          <div className="flex mt-4">
            <p className="flex-1 font-semibold max-w-72">Insured Name</p>
            {!form.insuredName ? (
              <p className="font-bold text-rose-600 justify-self-end">
                Missing Insured Name
              </p>
            ) : (
              <p className="font-normal">{form.insuredName}</p>
            )}
          </div>

          <div className="flex mt-4">
            <p className="flex-1 font-semibold max-w-72">Notice Type</p>
            <p className="font-normal">{noticeTypeMapping[form.noticeType]}</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full p-8 mt-8 bg-white rounded-lg shadow">
        <div className="flex flex-row items-center">
          <h5 className="text-xl font-bold">Incident Details</h5>
          <Button
            data-testid="edit-incident-details-button"
            onClick={() => onEdit('incident-details')}
          >
            <Edit className="mr-2" /> Edit
          </Button>
        </div>
        <Divider className="py-2" />

        <div className="mt-4">
          <div className="flex">
            <p className="flex-1 font-semibold max-w-72">Date & Time</p>
            <p className="font-normal">
              {format(form.lossDate, 'MM-dd-yyyy hh:mm a')}
            </p>
          </div>

          <div className="flex mt-4">
            <p className="flex-1 font-semibold max-w-72">State</p>
            {!form.lossState ? (
              <p className="font-bold text-rose-600 justify-self-end">
                Missing State
              </p>
            ) : (
              <p className="font-normal">{usStatesMapping[form.lossState]}</p>
            )}
          </div>

          <div className="flex mt-4">
            <p className="flex-1 font-semibold max-w-72">Location Details</p>
            <p className="font-normal">{form.lossLocation || '-'}</p>
          </div>

          <div className="flex mt-4">
            <p className="flex-1 font-semibold max-w-72">Policy Number</p>
            {!form.policyNumber ? (
              <p className="font-bold text-rose-600 justify-self-end">
                Missing Policy Number
              </p>
            ) : (
              <p className="font-normal">{form.policyNumber}</p>
            )}
          </div>

          <Show when={formType === 'insured'}>
            <div className="flex mt-4">
              <p className="flex-1 font-semibold max-w-72">Line of Business</p>
              <p className="font-normal text-normal">
                {policyCoveragesMapping[form.lineOfBusiness] || '-'}
              </p>
            </div>
          </Show>

          <div className="flex mt-4">
            <p className="flex-1 font-semibold max-w-72">
              Was your vehicle involved?
            </p>

            <div>
              <p className="font-normal capitalize">
                {form.ownVehiclesInvolved}
              </p>

              {insuredVehicleVins.map((vin: string, index: number) => (
                <p key={index} className="mt-2 font-normal">
                  {vin}
                </p>
              ))}
            </div>
          </div>

          <div className="flex mt-4">
            <p className="flex-1 font-semibold max-w-72">
              Was another vehicle involved?
            </p>

            <div>
              <p className="font-normal capitalize">
                {form.otherVehiclesInvolved}
              </p>

              {otherVehicleVins.map((vin: string, index: number) => (
                <p key={index} className="mt-2 font-normal">
                  {vin}
                </p>
              ))}
            </div>
          </div>

          <div className="flex mt-4">
            <p className="flex-1 font-semibold max-w-72">
              Was there police at the scene?
            </p>
            <p className="font-normal capitalize">{form.police.onTheScene}</p>
          </div>

          <div className="flex mt-4">
            <p className="flex-1 font-semibold max-w-72">Was anyone injured?</p>
            <p className="font-normal capitalize">{form.injureds}</p>
          </div>

          <div className="flex mt-4">
            <p className="flex-1 font-semibold max-w-72">Police Agency Name</p>
            <p className="font-normal capitalize">
              {form.police.agencyName || '-'}
            </p>
          </div>

          <div className="flex mt-4">
            <p className="flex-1 font-semibold max-w-72">
              Police Report Number
            </p>
            <p className="font-normal">{form.police.reportNumber || '-'}</p>
          </div>

          <div className="flex mt-4">
            <div className="flex-1 max-w-72">
              <p className="font-semibold">Incident Description</p>
            </div>

            <div className="flex-1">
              <p className="font-normal whitespace-pre-wrap">
                {form.description}
              </p>
            </div>
          </div>

          <div className="flex mt-4">
            <p className="flex-1 font-semibold max-w-72">Attachments</p>

            <div className="flex flex-col">
              {attachments.map((attachment: Attachment, index: number) => (
                <div
                  className="p-1 mb-2 border border-solid rounded-md border-primary-tint3"
                  key={`file-${index}`}
                >
                  <FileListItem file={attachment.file} />
                </div>
              ))}
            </div>
          </div>
          {rejectedAttachments && rejectedAttachments.length > 0 ? (
            <div className="flex">
              <span className="text-error-main">
                Some of the uploaded files are not supported and will not be
                submitted. Go to Incident Details to see them.
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
