import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = { context: { clientName: 'gqlgen' } } as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  Duration: any;
  Map: any;
  Time: any;
  UUID: any;
  Upload: any;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: User;
};

export type MutationCreateUserArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['ID'];
  hasNextPage: Scalars['Boolean'];
  startCursor: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  user?: Maybe<User>;
  usersConnection: UsersConnection;
};

export type QueryUserArgs = {
  id: Scalars['UUID'];
};

export type QueryUsersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type Role = {
  __typename?: 'Role';
  agencyID?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Time'];
  domain: Scalars['String'];
  fleetID?: Maybe<Scalars['ID']>;
  group: RoleGroup;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Time']>;
  userID: Scalars['ID'];
};

export enum RoleGroup {
  AgencyAdminRole = 'AgencyAdminRole',
  FleetAdminRole = 'FleetAdminRole',
  FleetReaderRole = 'FleetReaderRole',
  SuperuserRole = 'SuperuserRole',
}

export type User = {
  __typename?: 'User';
  activatedAt?: Maybe<Scalars['Time']>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Time']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['ID'];
  node: User;
};

export type UsersConnection = {
  __typename?: 'UsersConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
};

export type GetUserContactInfoQueryVariables = Exact<{
  userId: Scalars['UUID'];
}>;

export type GetUserContactInfoQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null;
  } | null;
};

export const GetUserContactInfoDocument = gql`
  query getUserContactInfo($userId: UUID!) {
    user(id: $userId) {
      id
      firstName
      lastName
      phoneNumber
    }
  }
`;

/**
 * __useGetUserContactInfoQuery__
 *
 * To run a query within a React component, call `useGetUserContactInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserContactInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserContactInfoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserContactInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserContactInfoQuery,
    GetUserContactInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserContactInfoQuery,
    GetUserContactInfoQueryVariables
  >(GetUserContactInfoDocument, options);
}
export function useGetUserContactInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserContactInfoQuery,
    GetUserContactInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserContactInfoQuery,
    GetUserContactInfoQueryVariables
  >(GetUserContactInfoDocument, options);
}
export type GetUserContactInfoQueryHookResult = ReturnType<
  typeof useGetUserContactInfoQuery
>;
export type GetUserContactInfoLazyQueryHookResult = ReturnType<
  typeof useGetUserContactInfoLazyQuery
>;
export type GetUserContactInfoQueryResult = Apollo.QueryResult<
  GetUserContactInfoQuery,
  GetUserContactInfoQueryVariables
>;
