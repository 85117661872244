import React from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { MenuItem, Select } from '@material-ui/core';

import { policyCoveragesMapping } from '../constants';
import { ClaimType, Policies } from '../contexts/newClaim';

type SelectPolicyNumberProps = {
  policies: Policies | undefined;
  isLoadingPolicies: boolean;
  setValue: UseFormSetValue<ClaimType>;
  register: UseFormRegister<ClaimType>;
  watch: UseFormWatch<ClaimType>;
};

export const SelectPolicyNumber = ({
  policies,
  isLoadingPolicies,
  setValue,
  register,
  watch,
}: SelectPolicyNumberProps) => {
  const noPolicies = (policies ?? []).length === 0;
  const policyNumber = watch('policyNumber');

  return (
    <Select
      {...register('policyNumber')}
      fullWidth
      value={policyNumber}
      variant="outlined"
      disabled={isLoadingPolicies || noPolicies}
      displayEmpty={noPolicies}
    >
      {noPolicies ? (
        <p>No active policies at incident time</p>
      ) : (
        policies?.map((policy) => (
          <MenuItem
            key={policy.id}
            value={policy.policyNumber}
            onClick={() => setValue('lineOfBusiness', policy.coverages[0])}
          >
            {`${policy.policyNumber} / ${
              policyCoveragesMapping[policy.coverages[0]]
            }`}
          </MenuItem>
        ))
      )}
    </Select>
  );
};
