import { ReactNode } from 'react';

import { IoAlertCircleOutline } from 'react-icons/io5';
import { HiOutlineShieldCheck } from 'react-icons/hi';

export const IconMapping = (percentile: number): ReactNode => {
  if (percentile <= 50) {
    return (
      <HiOutlineShieldCheck className="bg-success-main/10 text-success-main" />
    );
  } else if (percentile <= 80) {
    return (
      <IoAlertCircleOutline className="bg-warning-main/10 text-warning-main" />
    );
  } else {
    return (
      <IoAlertCircleOutline className="bg-error-main/10 text-error-main" />
    );
  }
};
