import {
  ClaimStatus,
  ClaimStatusChange,
  useClaimByIdQuery,
} from 'src/types/graphql-types';
import { Chip, getFormattedDate, Show } from '@nirvana/ui-kit';
import {
  MdAutoAwesome,
  MdNumbers,
  MdOutlineCalendarMonth,
  MdOutlineWatchLater,
  MdPersonOutline,
} from 'react-icons/md';
import { HiOutlineDocumentSearch } from 'react-icons/hi';
import { IconType } from 'react-icons';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import parseISO from 'date-fns/parseISO';
import startOfWeek from 'date-fns/startOfWeek';
import enUS from 'date-fns/locale/en-US';
import addDays from 'date-fns/addDays';
import { formatDateAgo } from './utils/formatDateAgo';
import { StatusChip } from './components/StatusChip';
import { useCollectClaimsFeedback } from './hooks/useCollectClaimsFeedback';
import { TimelineItem } from './components/TimeLineItem';

type ClaimDetailProps = {
  Icon?: IconType;
  name: string;
  value: string;
  href?: string;
  className?: string;
  helper?: string;
};

const ClaimDetail = ({
  Icon,
  name,
  value,
  href,
  className,
  helper,
}: ClaimDetailProps) => {
  return (
    <div className="flex items-center gap-1 py-1">
      {Icon ? (
        <div>
          <Icon className=" text-text-hint" />
        </div>
      ) : null}
      <h4>{name}</h4>
      <span className={clsx('text-secondary-main', className)} title={helper}>
        <Show when={href} fallback={value}>
          <a href={href} target="_blank" rel="noreferrer">
            {value}
          </a>
        </Show>
      </span>
    </div>
  );
};

type ClaimHistory = {
  title: string;
  date: Date;
  sortDate: Date;
  summary?: string;
  showFeedback?: boolean;
  currentFeedback?: boolean | null;
  id: string;
}[];

type ValidClaimStatus =
  | ClaimStatus.Closed
  | ClaimStatus.Reopen
  | ClaimStatus.Open;

const getTitleForStatus = (status: ValidClaimStatus) => {
  const texts = {
    [ClaimStatus.Open]: 'Claim Opened',
    [ClaimStatus.Closed]: 'Claim Closed',
    [ClaimStatus.Reopen]: 'Claim Reopened',
  };
  return texts[status];
};

function validClaimStatus(claimStatus: {
  value: ClaimStatus;
}): claimStatus is ClaimStatusChange & { value: ValidClaimStatus } {
  return [ClaimStatus.Closed, ClaimStatus.Reopen].includes(claimStatus.value);
}

export const ClaimDetails = () => {
  const { enableFeedbackModalForClaim } = useCollectClaimsFeedback();
  const [claimHistory, setClaimHistory] = useState<ClaimHistory>();
  const [lastUpdated, setLastUpdated] = useState<string>();
  const { claimId = '' } = useParams<{ claimId: string }>();
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();
  const { data } = useClaimByIdQuery({
    variables: { id: claimId },
    skip: !claimId,
    onCompleted: (data) => {
      // we wait 2s before marking the claim as feeedback ready
      // this is to prevent cases when the users opens the wrong
      // claim and we ask them for feedback
      if (data.claimById?.canSubmitFeedback) {
        timeoutId.current = setTimeout(() => {
          enableFeedbackModalForClaim(claimId);
        }, 2_000);
      }

      const lastClaimByDateId = data.summariesForClaimId.toSorted(
        (a, b) =>
          parseISO(b.intervalEnd).getTime() - parseISO(a.intervalEnd).getTime(),
      )?.[0]?.id;

      const claimHistory: ClaimHistory = data.summariesForClaimId.map(
        (summary) => {
          const parsedIntervalEnd = parseISO(summary.intervalEnd);
          return {
            title: summary.title,
            /* We want to make sure the summary is shown on Monday of the week
             that we generated it, even if in sometimezones the date can be
             considered as Sunday.
            */
            date: startOfWeek(addDays(parsedIntervalEnd, 1), {
              locale: enUS,
              weekStartsOn: 1,
            }),
            sortDate: parsedIntervalEnd,
            summary: summary.summary,
            showFeedback: lastClaimByDateId === summary.id,
            currentFeedback: summary.feedback,
            id: summary.id,
          };
        },
      );

      data.claimById?.statusChanges
        .filter((change) => validClaimStatus(change))
        .forEach((change) => {
          claimHistory.push({
            title: getTitleForStatus(change.value),
            date: parseISO(change.createdAt),
            sortDate: parseISO(change.createdAt),
            showFeedback: false,
            id: change.id,
          });
        });

      // We can't trust the history event Open, so we set it from the reportedAt
      if (data.claimById?.reportedAt) {
        claimHistory.push({
          title: getTitleForStatus(ClaimStatus.Open),
          date: parseISO(data.claimById.reportedAt),
          sortDate: parseISO(data.claimById.reportedAt),
          showFeedback: false,
          id: 'open',
        });
      }

      // In some cases claims are updated after they are closed
      // but as we are not showing the updates that it received, we use the earliest
      // between the modifiedAt and the status change to Closed
      const closedAt = data.claimById?.statusChanges
        .toSorted(
          (a, b) =>
            parseISO(b.createdAt).getTime() - parseISO(a.createdAt).getTime(),
        )
        .find((event) => event.value === ClaimStatus.Closed)?.createdAt;

      let lastUpdated = data.claimById?.modifiedAt;

      if (
        closedAt &&
        parseISO(closedAt).getTime() <
          parseISO(data.claimById?.modifiedAt!).getTime()
      ) {
        lastUpdated = closedAt;
      }
      setLastUpdated(lastUpdated);

      claimHistory.sort((a, b) => b.sortDate.getTime() - a.sortDate.getTime());

      setClaimHistory(claimHistory);
    },
  });

  // todo: move this to a onSuccess callback of the graphql query
  useEffect(() => {
    return () => timeoutId.current && clearTimeout(timeoutId.current);
  }, []);

  const claimDetails = data?.claimById;

  if (!claimDetails) {
    return null;
  }

  return (
    <article
      className="max-w-2xl mx-auto font-medium"
      data-testid="claim-details"
    >
      <header>
        <StatusChip status={claimDetails.status} />
        <h1 className="flex flex-wrap gap-2 mt-6 mb-4 text-2xl">
          <strong>Claim</strong>{' '}
          <span className="text-text-hint">#{claimDetails.externalId}</span>
          <Chip label={claimDetails.lineOfBusiness} />
        </h1>
        <section>
          <div className="flex flex-wrap gap-x-8">
            <ClaimDetail
              Icon={MdOutlineWatchLater}
              name="Last Action"
              helper={
                lastUpdated ? getFormattedDate(lastUpdated, 'MMM dd yyyy') : ''
              }
              value={lastUpdated ? formatDateAgo(lastUpdated) : 'Unknown'}
            />
            <ClaimDetail
              Icon={MdOutlineCalendarMonth}
              name="Created"
              value={getFormattedDate(claimDetails.reportedAt, 'MMM dd yyyy')}
            />
          </div>
          <div className="flex flex-wrap gap-x-8">
            <ClaimDetail
              Icon={MdPersonOutline}
              name="Submitted By"
              className="capitalize"
              value={claimDetails.reportedBy?.toLocaleLowerCase() || '-'}
            />
            <ClaimDetail
              Icon={MdNumbers}
              name="Policy ID"
              value={claimDetails.policyNumber}
            />
            <ClaimDetail
              Icon={HiOutlineDocumentSearch}
              name="Adjuster"
              href={`mailto:${claimDetails.adjusterEmail}`}
              value={claimDetails.adjusterName}
            />
          </div>
        </section>
      </header>
      <section>
        <h2 className="flex gap-4 mt-8 text-base text-secondary-main">
          <span>Weekly Updates</span>
          <span className="text-text-hint">
            <MdAutoAwesome className="inline-block" /> Generated by Nirvana AI
          </span>
        </h2>
        <p className="mt-2 text-xs text-text-hint">
          Note: Weekly updates are AI-generated and may not capture all details.
        </p>
        <ol className="pt-6 pl-4">
          {claimHistory?.map((update) => (
            <TimelineItem
              key={update.id}
              title={update.title}
              date={update.date}
              summary={update.summary}
              showFeedback={
                update.showFeedback && data.claimById?.canSubmitFeedback
              }
              currentFeedback={update.currentFeedback}
              itemId={update.id}
            />
          ))}
        </ol>
      </section>
    </article>
  );
};
