import { ExplainabilityRecommendation } from 'src/types/graphql-types';
import { Button } from '@material-ui/core';
import { HiChevronRight } from 'react-icons/hi';
import { Show } from '@nirvana/ui-kit';
import { IconMapping } from '../constants/explainabilityIcons';

type RecommendationActionsProps = {
  onModalOpen: (index: number) => void;
  hasExplainabilityRecommendations: boolean;
  explainabilityRecommendations: ExplainabilityRecommendation[];
};

const RecommendedAction = ({
  onModalOpen,
  recommendation,
}: RecommendedActionProps) => {
  return (
    <div
      key={recommendation.category}
      data-testid="recommendation-item"
      className="flex items-start p-3 mb-4 border rounded-md"
    >
      <div className="p-1 mr-4 text-2xl rounded">
        {IconMapping(recommendation.percentile)}
      </div>
      <div>
        <p className="mb-1 font-bold">{recommendation.category}</p>
        <div className="flex flex-col">
          {recommendation.recommendedAmountComparison.map((comparison) => (
            <div key={comparison} className="mb-1">
              {comparison}
            </div>
          ))}
        </div>
        <Button
          sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
          endIcon={<HiChevronRight />}
          onClick={onModalOpen}
        >
          Learn More
        </Button>
      </div>
    </div>
  );
};

const RecommendedActions = ({
  onModalOpen,
  hasExplainabilityRecommendations,
  explainabilityRecommendations,
}: RecommendationActionsProps) => {
  const emptyStateText = hasExplainabilityRecommendations
    ? [
        'Your fleet is maintaining good performance',
        "We'll continue monitoring your telematics data and provide specific recommendations if opportunities for improvement arise.",
      ]
    : [
        'Generating recommended actions...',
        "We're still processing tailored recommendations for your fleet. Check back soon to see specific contributing factors and recommendations.",
      ];

  return (
    <>
      <span className="ml-2 mb-4 font-bold text-text-primary text-gray-800">
        Factors that impact your score
      </span>
      <Show
        when={explainabilityRecommendations.length > 0}
        fallback={
          <div className="flex flex-col items-center justify-center mt-32">
            <div className="flex flex-col items-center justify-center md:w-96 text-center">
              <div className="font-bold mb-4">{emptyStateText[0]}</div>
              {emptyStateText[1]}
            </div>
          </div>
        }
      >
        {explainabilityRecommendations.map((recommendation, idx) => (
          <RecommendedAction
            onModalOpen={() => onModalOpen(idx)}
            recommendation={recommendation}
            key={recommendation.category}
          />
        ))}
      </Show>
    </>
  );
};

type RecommendedActionProps = {
  onModalOpen: () => void;
  recommendation: ExplainabilityRecommendation;
};

export default RecommendedActions;
