import { Category, Tsp } from 'src/types/graphql-types';
import samsara from 'src/assets/images/samsara.png';
import Motive from 'src/assets/images/motive.svg';

export const AUTH_TOKEN_STORAGE_KEY = 'nirvana__auth_token';
export const SHARE_TOKEN_STORAGE_KEY = 'nirvana__share_token';
export const SHAREID_QUERY_PARAM = 'shareid';
export const SESSIONID_QUERY_PARAM = 'sessionId';

export const tspProviders = [
  { logo: samsara, key: Tsp.TspSamsaraSafety, alt: 'samsara' },
  { logo: Motive, key: Tsp.TspKeepTruckinSafety, alt: 'motive' },
];

export enum ExtendedCategory {
  Pending = 'Pending',
}

export const BASICS = {
  [Category.ControlledSubstancesAlcohol]: 'Controlled Substances Alcohol',
  [Category.CrashIndicator]: 'Crash Indicator',
  [Category.DriverFitness]: 'Driver Fitness',
  [Category.HmCompliance]: 'HM Compliance',
  [Category.HosCompliance]: 'HOS Compliance',
  [Category.InsuranceOther]: 'Insurance Other',
  [Category.UnsafeDriving]: 'Unsafe Driving',
  [Category.Unspecified]: 'Unspecified',
  [Category.VehicleMaintenance]: 'Vehicle Maintenance',
  [ExtendedCategory.Pending]: 'Pending',
};

export const MOCK_DOTS = ['123456'];

export const claimsRegex = /^\/claims\/[^/]+$/;
