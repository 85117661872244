import { Navigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

export const ClaimsRedirect = () => {
  const { user } = useAuth();
  if (user?.userType === 'fleet') {
    return <Navigate to={`/${user.reportId}/claims`} />;
  } else {
    return <Navigate to="/search" />;
  }
};
