import { FC, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import useAuth, { isAnonymousUser, isFleetUser } from 'src/hooks/useAuth';
import { useSafetyReportQuery } from 'src/types/graphql-types';
import { CircularProgress } from '@material-ui/core';

const AuthGuard: FC = ({ children }) => {
  const location = useLocation();
  const { reportId = '' } = useParams();
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { clearStore } = useApolloClient();

  const isReportIdaDOT = !isNaN(Number(reportId));

  useEffect(() => {
    if (!isAuthenticated) {
      clearStore();
    }
  }, [clearStore, isAuthenticated]);

  /*
  Redirect users with safety report ids to DOT number
  Possible scenarios:
  1. DOT IN URL: User is from nirvana and has access to all Safety Reports, but never visited this DOT number before -> Do nothing
  2. DOT IN URL: User is from nirvana and has access to all Safety Reports, but visited this DOT number before -> Do nothing
  3. SAFETY REPORT IN URL: User is from nirvana and has access to all Safety Reports, but enters with a Safety Report ID -> Redirect to DOT number
  4. SAFETY REPORT IN URL: User has a fleetReader/Admin role in a DOT, but visits other -> Redirect to they own Safety Report (Then 6 should kick in)
  5. DOT IN URL: User has a fleetReader/Admin role in a DOT, but visits other-> Do nothing (The non telematics data is public and is being handled on the backend)
  6. DOT IN URL: User has a fleetReader/Admin role in a DOT, and visits this DOT number -> Do nothing
  */
  const navigateWithNewReportId = (newReportId: string) => {
    const currentLocation = location;
    const currentPath = currentLocation.pathname.split('/');
    if (currentPath.length > 2 && reportId.length > 0) {
      const positionOfCurrentReportId = currentPath.indexOf(reportId);
      currentPath[positionOfCurrentReportId] = newReportId;
      currentLocation.pathname = currentPath.join('/');
    }
    return navigate({
      pathname: currentLocation.pathname,
      search: currentLocation.search,
    });
  };

  const { loading } = useSafetyReportQuery({
    variables: { reportId },
    skip: !reportId || isReportIdaDOT,
    onCompleted: ({ fleetSafetyReport }) => {
      if (!fleetSafetyReport) {
        return;
      }
      const { dotNumber } = fleetSafetyReport || {};

      navigateWithNewReportId(dotNumber);
    },
    onError: () => {
      if (user?.reportId && (isFleetUser(user) || isAnonymousUser(user))) {
        return navigateWithNewReportId(user.reportId);
      }
      return navigate('/');
    },
  });

  if (!loading && !isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  return <>{children}</>;
};

export default AuthGuard;
