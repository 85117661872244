import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  ClaimsByDotNumberQuery,
  ReportsQuery,
  RoleGroupEnum,
  useClaimsByDotNumberQuery,
  useReportsQuery,
} from 'src/types/graphql-types';

import { Button } from '@material-ui/core';
import { Feature } from 'src/helpers/feature-flags';
import { useDOTSpecificFeature } from 'src/hooks/useDotSpecificFeature';
import { Show, TableTabs, TableV8 } from '@nirvana/ui-kit';
import useAuth from 'src/hooks/useAuth';
import { getClaimsColumns, getReportsColumns } from './constants/columns';
import { EmptyResults } from './components/EmptyResults';
import { Tabs } from './constants/types';
import { useTab } from './hooks/useTab';
import { ClaimRating } from './components/ClaimRating';

const initialState = {
  sorting: [
    {
      id: 'status',
      desc: false,
    },
  ],
};

const pageOptions = {
  pageSize: 10,
};

export const Claims = () => {
  const { reportId } = useParams<{ reportId: string }>();
  const { enabled: fnolReportsAvailale } = useDOTSpecificFeature(
    Feature.REPORT_FNOL_ENABLED_BY_DOT,
    {
      allowUserOverride: true,
    },
  );
  const { enabled: detailsPageEnabled } = useDOTSpecificFeature(
    Feature.CLAIMS_DETAILS,
    {
      allowUserOverride: true,
    },
  );
  const [claimsColumnsVisibility, setClaimsColumnsVisibility] = useState({});

  const [selectedTab, setSelectedTab] = useTab();
  const navigate = useNavigate();

  const { data: claims, loading: areClaimsLoading } = useClaimsByDotNumberQuery(
    {
      variables: { dotNumber: reportId as string },
    },
  );

  const { data: reports, loading: areReportsLoading } = useReportsQuery({
    skip: !fnolReportsAvailale,
  });

  const claimsData =
    claims?.claimsByDOTNumber ??
    ([] as ClaimsByDotNumberQuery['claimsByDOTNumber']);
  const reportsData = reports?.fnols ?? ([] as ReportsQuery['fnols']);

  const isLoading = areClaimsLoading || areReportsLoading;

  useEffect(() => {
    if (!fnolReportsAvailale && selectedTab === Tabs.Reports) {
      setSelectedTab(Tabs.Claims);
    }
  }, [selectedTab, setSelectedTab, fnolReportsAvailale]);

  const { user } = useAuth();

  const showReportClaimsButton = useMemo(() => {
    const isNirvanaSuperuser = user?.roles?.nirvanaRoles
      ? user.roles.nirvanaRoles.filter(
          ({ role }) => role === RoleGroupEnum.SuperuserRole,
        )?.length > 0
      : false;
    const isFleetAdmin = user?.roles?.fleetRoles
      ? user.roles.fleetRoles.filter(
          ({ role }) => role === RoleGroupEnum.FleetAdminRole,
        )?.length > 0
      : false;
    return fnolReportsAvailale && (isNirvanaSuperuser || isFleetAdmin);
  }, [fnolReportsAvailale, user?.roles]);

  const ReportClaimButton = () => (
    <Show when={showReportClaimsButton}>
      <Link data-testid="report-button" to="new">
        <Button variant="contained">Report a Claim</Button>
      </Link>
    </Show>
  );

  useEffect(() => {
    if (!detailsPageEnabled) {
      setClaimsColumnsVisibility({
        view_details: false,
      });
    } else {
      setClaimsColumnsVisibility({});
    }
  }, [detailsPageEnabled]);

  return (
    <div>
      <ClaimRating />
      <header>
        <div className="flex flex-row justify-between">
          <h1 className="text-3xl font-bold text-secondary-main">Claims</h1>
          <ReportClaimButton />
        </div>
        <Show when={fnolReportsAvailale}>
          <div className="flex items-center justify-between">
            <div className="inline-block">
              <TableTabs
                tabs={[
                  { id: 'Claims', label: 'Claims', value: Tabs.Claims },
                  {
                    id: 'Reports',
                    label: 'Online Incident Reports',
                    value: Tabs.Reports,
                  },
                ]}
                onChange={(newTab) => {
                  setSelectedTab(newTab);
                }}
                value={selectedTab}
              />
            </div>
          </div>
        </Show>
      </header>

      <Show when={selectedTab === Tabs.Reports}>
        <p className="py-4">
          These are the incidents you&apos;ve reported online directly to
          Nirvana. Once an adjuster is assigned, the claim will appear in the
          Claims tab.
        </p>
      </Show>

      {!isLoading &&
      ((selectedTab === Tabs.Reports && reportsData.length === 0) ||
        (selectedTab === Tabs.Claims && claimsData.length === 0)) ? (
        <div className="flex flex-col items-center">
          <EmptyResults type={selectedTab} />
          <ReportClaimButton />
        </div>
      ) : (
        <div className="flex-1 pb-4 mt-4 overflow-auto bg-white rounded-md shadow">
          {selectedTab === Tabs.Reports && (
            <TableV8
              data={reportsData}
              columns={getReportsColumns()}
              pageOptions={pageOptions}
            />
          )}
          {selectedTab === Tabs.Claims && (
            <TableV8
              data={claimsData}
              columns={getClaimsColumns()}
              columnVisibility={claimsColumnsVisibility}
              initialState={initialState}
              pageOptions={pageOptions}
              onRow={(row) => ({
                className: detailsPageEnabled
                  ? 'cursor-pointer hover:bg-primary-extraLight/[0.65]'
                  : '',
                onClick: (e) => {
                  if (!detailsPageEnabled) {
                    return;
                  }
                  // check if should open in new tab
                  if (e.ctrlKey || e.metaKey) {
                    window.open(`claims/${row.id}`, '_blank');
                  } else {
                    navigate(row.id);
                  }
                },
              })}
            />
          )}
        </div>
      )}
    </div>
  );
};
