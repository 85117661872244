import { SyntheticEvent, useState } from 'react';

import { Dialog } from '@nirvana/ui-kit';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { HiOutlineShieldCheck } from 'react-icons/hi';

type scoreExplanationModalProps = {
  open: boolean;
  setScoreExplanationModalOpen: any;
};

const scoreExplanationText =
  'Your fleet’s telematics data is used to calculate a monthly score designed to give an understanding of the driving behaviors and key risk factors Nirvana prioritizes. These scores reflect both controllable risks, like braking habits, and unavoidable risks, such as traffic congestion or route requirements.';

const scoreBuckets = [
  ['Excellent: ', '0-29 (Safe and efficient driving)'],
  ['Good: ', '30-59 (Generally safe with room for improvement)'],
  ['Fair: ', '60-79 (Moderate risk; requires attention)'],
  ['Poor: ', '80-100 (High risk; action needed)'],
];

const factorsExplanationText = [
  'The Nirvana Safety Score reflects the overall impact of multiple factors working together, including some that may not be directly visible. While certain risks are inherent to your fleet’s operations and may not always be eliminated, understanding these dynamics can help you address the most impactful areas and enhance fleet performance.',
  'Below, we highlight the primary factors—such as harsh braking, congestion, and local driving—that significantly contribute to the Nirvana Safety Score.',
];

type categoryExplanation = {
  title: string;
  content: string[];
};

const categoryExplanations: categoryExplanation[] = [
  {
    title: 'Harsh Braking',
    content: [
      'Harsh braking occurs when a driver applies sudden, intense brake pressure.We identify these events through telematics data registering above 0.2 g - forces.',
      "High instances of harsh braking increases the fleet's score and can reflect reactive rather than proactive driving. Maintaining smooth braking patterns will improve your score.",
    ],
  },
  {
    title: 'Congestion',
    content: [
      'Operating in congested areas increases exposure to potential accidents. High congestion is identified using telematics data, which captures patterns of heavy stop-and-go behavior and areas with heavy traffic. The score reflects these conditions, highlighting the associated risks. While fleets may not always be able to avoid high-congestion areas, optimizing routes and providing coaching on operating safely in these conditions can help prioritize safety measures.',
    ],
  },
  {
    title: 'Local driving',
    content: [
      'Driving in local areas often comes with unique challenges that increase the risk of accidents.  Local driving is identified using telematics data, focusing on average speeds below 50 mph. The score reflects these conditions, highlighting risks such as frequent stops, intersections, and more interactions with pedestrians or other vehicles.',
      'While fleets may not always be able to avoid local driving, providing coaching on navigating these environments safely can help reduce risks.',
    ],
  },
];

const ScoreExplanationModal = ({
  open,
  setScoreExplanationModalOpen,
}: scoreExplanationModalProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Dialog open={open}>
      <div className="relative flex flex-col items-left px-4 pt-2 pb-6 text-left">
        <IconButton
          aria-label="close"
          className="absolute top-0 right-0 text-gray-500"
          onClick={() => setScoreExplanationModalOpen(false)}
          size="small"
        >
          <CloseIcon />
        </IconButton>

        <div className="flex items-center mb-5">
          <div className="p-1 text-xl rounded text-primary-main bg-primary-extraLight mr-2">
            <HiOutlineShieldCheck />
          </div>
          <h2 className="text-xl font-semibold text-text-primary">
            Nirvana Safety Score
          </h2>
        </div>
        <div className="mb-5">{scoreExplanationText}</div>
        <div className="font-bold mb-5">
          Scores are represented on a 0-100 scale:
        </div>
        <ul className="list-disc ml-5">
          {scoreBuckets.map((t) => (
            <li key={JSON.stringify(t)} className="mb-5">
              <div>
                <span className="font-bold">{t[0]}</span>
                <span>{t[1]}</span>
              </div>
            </li>
          ))}
        </ul>
        <div className="font-bold mb-3">Factors that impact your score</div>
        <div className="mb-3">{factorsExplanationText[0]}</div>
        <div className="mb-3">{factorsExplanationText[1]}</div>

        <div className="w-100">
          <Tabs className="mb-5" value={value} onChange={handleChange}>
            {categoryExplanations.map((t) => (
              <Tab
                key={JSON.stringify(t)}
                label={t.title}
                style={{ minWidth: '120px' }}
                sx={{ textTransform: 'none' }}
              />
            ))}
          </Tabs>
        </div>

        <div className="space-y-3 mb-2">
          <div className="text-xl font-semibold">
            {categoryExplanations[value].title}
          </div>
          <div className="font-bold">What does it mean?</div>
          {categoryExplanations[value].content.map((content) => (
            <div key={content}>{content}</div>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export default ScoreExplanationModal;
