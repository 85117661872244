import { FormHelperText } from '@material-ui/core';
import { Show } from '@nirvana/ui-kit';
import clsx from 'clsx';
import React, { useState } from 'react';

interface Option<T> {
  value: T;
  label: string;
}

interface BaseMultiSelectButtonsProps<T> {
  options: Option<T>[];
  label?: string;
  error?: string;
  testId?: string;
}

interface SingleSelectButtonsProps<T> extends BaseMultiSelectButtonsProps<T> {
  multiple?: false;
  onChange: (selected: T) => void;
}

interface MultiSelectButtonsProps<T> extends BaseMultiSelectButtonsProps<T> {
  multiple: true;
  onChange: (selected: T[]) => void;
}

type Props<T> = SingleSelectButtonsProps<T> | MultiSelectButtonsProps<T>;

const MultiSelectButtons = <T, >({
  options,
  label,
  multiple = false,
  onChange,
  error,
  testId,
}: Props<T>) => {
  const [selectedOptions, setSelectedOptions] = useState<T[]>([]);

  const handleToggle = (option: T) => {
    let newSelectedOptions: T[];

    if (multiple) {
      newSelectedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((item) => item !== option)
        : [...selectedOptions, option];
      (onChange as MultiSelectButtonsProps<T>['onChange'])(newSelectedOptions);
    } else {
      newSelectedOptions = selectedOptions.includes(option) ? [] : [option];
      (onChange as SingleSelectButtonsProps<T>['onChange'])(
        newSelectedOptions[0],
      );
    }

    setSelectedOptions(newSelectedOptions);
  };

  return (
    <div data-testid={testId}>
      <Show when={label}>
        <label
          htmlFor="multi-select"
          className="flex mt-4 mb-2 text-xs font-medium text-text-primary"
        >
          {label}
        </label>
      </Show>
      <div className="flex flex-wrap gap-2" id="multi-select">
        {options.map((option) => (
          <button
            key={String(option.value)}
            type="button"
            onClick={() => handleToggle(option.value)}
            className={clsx(
              'px-2 border py-1 rounded-md transition duration-300 text-xs',
              {
                'bg-gray-700 border-gray-700 text-white':
                  selectedOptions.includes(option.value),
                'border-text-disabled text-text-primary':
                  !selectedOptions.includes(option.value),
              },
            )}
          >
            {option.label}
          </button>
        ))}
      </div>
      {error && <FormHelperText>{error}</FormHelperText>}
    </div>
  );
};

export default MultiSelectButtons;
