import React from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {
  Autocomplete,
  createFilterOptions,
  FilterOptionsState,
  OutlinedInput,
} from '@material-ui/core';

import { ClaimType, Policies } from '../contexts/newClaim';

interface PolicyOptionType {
  inputValue?: string; // the user typed input
  label: string; // the policy number
}

type AutocompletePolicyNumberProps = {
  policies: Policies | undefined;
  isLoadingPolicies: boolean;
  setValue: UseFormSetValue<ClaimType>;
  register: UseFormRegister<ClaimType>;
  watch: UseFormWatch<ClaimType>;
};

export const AutocompletePolicyNumber = ({
  policies,
  isLoadingPolicies,
  setValue,
  register,
  watch,
}: AutocompletePolicyNumberProps) => {
  const policiesToPolicyOptionType: PolicyOptionType[] = (policies ?? []).map(
    ({ policyNumber }) => {
      const option: PolicyOptionType = { label: policyNumber };
      return option;
    },
  );

  const policyNumber = watch('policyNumber');
  const policyNumberOption: PolicyOptionType | undefined = policyNumber
    ? { label: policyNumber }
    : undefined;

  const policiesFilter = createFilterOptions<PolicyOptionType>({
    ignoreCase: true,
    limit: 10,
    stringify: (option: PolicyOptionType) => option.label,
    trim: true,
  });

  const filterOptions = (
    options: PolicyOptionType[],
    state: FilterOptionsState<PolicyOptionType>,
  ) => {
    const filtered = policiesFilter(options, state);
    const { inputValue } = state;
    // Suggest the creation of a new value
    const hasMatchingPolicyNumber = options.some(
      (option: PolicyOptionType) => inputValue === option.label,
    );
    if (inputValue !== '' && !hasMatchingPolicyNumber) {
      filtered.push({
        inputValue,
        label: inputValue,
      });
    }

    return filtered;
  };

  const onChange = (newValue: PolicyOptionType | null) => {
    if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setValue('policyNumber', newValue.inputValue);
    } else if (newValue) {
      // User selected a given option
      setValue('policyNumber', newValue.label);
    } else {
      // User clears field
      setValue('policyNumber', '');
    }
  };

  return (
    <Autocomplete
      {...register('policyNumber')}
      filterOptions={filterOptions}
      fullWidth
      loading={isLoadingPolicies}
      onChange={(_, value: PolicyOptionType | null) => onChange(value)}
      options={policiesToPolicyOptionType}
      renderInput={(params) => {
        return (
          <OutlinedInput
            {...params}
            {...params.InputProps}
            inputProps={params.inputProps}
            placeholder="E.g.: NISTK1234567-24"
          />
        );
      }}
      renderOption={(props, option) => (
        <li {...props}>{option.inputValue ?? option.label}</li>
      )}
      ListboxProps={{ id: 'policy-number-listbox' }}
      noOptionsText="No policies found. Enter policy number manually."
      value={policyNumberOption}
    />
  );
};
