import { useMemo } from 'react';
import { LineChart, Show } from '@nirvana/ui-kit';

type ProjectionChartProps = {
  chart: any;
};

function ProjectChartTooltip(props: any) {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    return (
      <div className="w-56 px-4 py-3 space-y-1 rounded-md bg-text-primary">
        <p className="font-semibold text-primary-tint1">Projection</p>
        {payload.map((val: any, i: number) => {
          return (
            <div className="flex items-center" key={i}>
              <div className={`w-1 h-1 mr-2 bg-[${val.stroke}] rounded-full`} />
              <span className="text-xs text-primary-tint2">{val.name}</span>
              <div className="flex-1" />
              <span className="font-semibold text-white">{val.value}</span>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
}

export default function ProjectionChart({ chart }: ProjectionChartProps) {
  const lineChartData = chart?.projection;

  const lineChartProps = useMemo(() => {
    if (lineChartData) {
      return {
        ...lineChartData,
        margin: { top: 20, bottom: 20, left: 20 },
        data: lineChartData.data.map((val: any) => {
          const dataObj: Record<string, number> = {
            xVal: val.xVal,
          };
          for (let i = 0; i < val.yVals.length; i++) {
            dataObj[`yVal${i.toString()}`] = val.yVals[i];
          }
          return dataObj;
        }),
        xField: 'xVal',
        yField: lineChartData.data[0].yVals.map(
          (e: any, i: number) => `yVal${i.toString()}`,
        ),
        lineConfig: lineChartData.lineConfigs.map((val: any, i: number) => {
          return {
            name: val.name,
            stroke: ['#00A0FF', '#D2D21E', '#1ED05A', '#1ED2BA', '#CC55E7'][
              i % 5
            ],
            strokeDasharray: val.dashed ? '8 3' : null,
          };
        }),
        legend: true,
        xAxis: {
          label: { value: lineChartData.xLabel, dy: 0 },
        },
        yAxis: {
          label: { value: lineChartData.yLabel },
        },
        tooltipProps: {
          content: <ProjectChartTooltip />,
        },
        referenceLines: [
          {
            y: lineChartData.threshold,
            stroke: '#FA3252',
            strokeDasharray: '8 4',
          },
        ],
      };
    }
  }, [lineChartData]);

  return (
    <Show when={lineChartData}>
      <div className="p-4 border rounded">
        <div className="flex items-center justify-between mb-4">
          <span className="font-semibold text-secondary-main">
            {chart?.title}
          </span>
          <Show when={lineChartData?.threshold}>
            <p>
              <span className="text-sm text-gray-500">Threshold:</span>{' '}
              <span className="font-semibold">{lineChartData?.threshold}%</span>
            </p>
          </Show>
        </div>
        <div className="h-80">
          <LineChart {...lineChartProps} />
        </div>
      </div>
    </Show>
  );
}
