import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import parseISO from 'date-fns/parseISO';

const DAYS_BEFORE_SWITCH_TO_FULL_DATE = 7;

export const formatDateAgo = (dateString: string) => {
  const date = parseISO(dateString);
  const today = new Date();
  if (
    Math.abs(differenceInDays(date, today)) > DAYS_BEFORE_SWITCH_TO_FULL_DATE
  ) {
    return format(date, 'MMM dd yyyy');
  }
  return formatDistance(date, today, { addSuffix: true });
};
