import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Navbar from 'src/components/navbar';
import Header from 'src/components/header';
import Sidebar from 'src/components/sidebar';
import { Drawer } from '@material-ui/core';
import { claimsRegex } from 'src/constants';
import { useResponsiveDrawer } from './hooks/useResponsiveDrawer';
import { useScrollRestoration } from './hooks/useScrollRestoration';

/**
 * AppShell component works as a common layout for different pages
 * It will render Navbar, Header, Sidebar along with page passed as children prop
 *
 * @returns
 */
export default function AppShell() {
  const location = useLocation();
  const { reportId = '' } = useParams();
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);

  const {
    handleDrawerToggle,
    handleDrawerClose,
    handleDrawerTransitionEnd,
    mobileOpen,
  } = useResponsiveDrawer();

  useEffect(() => {
    const pathname =
      location.pathname.split(`/${reportId}`)[1] ?? location.pathname;

    const isClaimDetailsOrNewClaim = claimsRegex.test(pathname);

    setIsSidebarHidden(isClaimDetailsOrNewClaim);
  }, [location, reportId, setIsSidebarHidden]);

  const containerRef = useRef<HTMLDivElement>(null);
  useScrollRestoration(containerRef);

  return (
    <div className="flex flex-col w-screen h-screen">
      <Navbar
        handleDrawerToggle={isSidebarHidden ? undefined : handleDrawerToggle}
      />

      <Header />
      <div className="flex flex-1 mt-1 overflow-hidden bg-gray-50">
        {!isSidebarHidden && (
          <>
            <Drawer
              test-dataid="drawer"
              variant="temporary"
              open={mobileOpen}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              className="md:hidden"
            >
              <Sidebar showLogo />
            </Drawer>

            <Sidebar className="hidden md:block" />
          </>
        )}
        <div
          className="flex flex-col items-center flex-1 px-6 py-6 overflow-y-auto"
          ref={containerRef}
        >
          <div className="flex flex-col flex-1 w-full max-w-screen-2xl">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
