import type { ReactNode } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
  ApolloLink,
  Observable,
} from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { storage } from '@nirvana/ui-kit';

import useAuth from 'src/hooks/useAuth';
import { AUTH_TOKEN_STORAGE_KEY, SHARE_TOKEN_STORAGE_KEY } from 'src/constants';
import * as Sentry from '@sentry/react';
import { mockDataRecord } from './mock-dot/mockData';

const MOCK_DOT = '123456';
const TEN_MINUTES = 1000 * 60 * 10;

export default function Apollo({ children }: { children: ReactNode }) {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const thunderLink = createHttpLink({
    uri: import.meta.env.VITE_GRAPHQL_ENDPOINT,
  });

  const gqlgenLink = createHttpLink({
    uri: import.meta.env.VITE_GQLGEN_ENDPOINT,
  });

  const httpLink = ApolloLink.split(
    (operation) => operation.getContext().clientName === 'thunder',
    thunderLink,
    gqlgenLink,
  );

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const accessToken = storage.get(AUTH_TOKEN_STORAGE_KEY);
    const shareToken = storage.get(SHARE_TOKEN_STORAGE_KEY);
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        JSESSIONID: accessToken ?? '',
        SHAREID: shareToken,
      },
    };
  });

  // prevents the app to show multiple snackbars when session expires
  const delay = 3000;
  let lastClick = 0;

  const errorLink = onError(({ operation, graphQLErrors }) => {
    const { response } = operation.getContext();
    if (response?.status === 401) {
      if (lastClick < Date.now() - delay) {
        lastClick = Date.now();
        enqueueSnackbar('Session expired! Please login again to continue', {
          variant: 'error',
        });
        logout().then(() => navigate('/', { state: { from: undefined } }));
      }
    }
    if (graphQLErrors) {
      Sentry.setContext('variables', operation.variables);
      const error = new Error(graphQLErrors.join('\n'));
      error.name = operation.operationName;
      Sentry.captureException(error);
    }
  });

  const userID = useAuth().user?.id || '';
  const mockData = mockDataRecord(userID);

  const mockDataLink = new ApolloLink((operation, forward) => {
    const isMockDOT = window.location.pathname.split('/')?.[1] === MOCK_DOT;

    let mockResponse = mockData[operation.operationName];

    if (isMockDOT && mockResponse) {
      if (operation.operationName === 'CompleteDriverViolationFetch') {
        storage.set('demo-dot-drivers-unlocked', Date.now().toString());
      }

      if (operation.operationName === 'violations') {
        const lastDemoUnlock = storage.get('demo-dot-drivers-unlocked');
        const unlockWithinFiveMinutes =
          lastDemoUnlock &&
          new Date().getTime() - parseInt(lastDemoUnlock) < TEN_MINUTES;
        if (unlockWithinFiveMinutes) {
          mockResponse = mockData.ViolationsWithDrivers;
        }
      }

      return new Observable((observer) => {
        observer.next(mockResponse);
        observer.complete();
      });
    }
    return forward(operation);
  });

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, mockDataLink, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
