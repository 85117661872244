import { Chip, getFormattedDate, Tooltip } from '@nirvana/ui-kit';
import { createColumnHelper } from '@tanstack/react-table';

import { HiOutlineInformationCircle } from 'react-icons/hi';
import {
  ClaimsByDotNumberQuery,
  ClaimStatus,
  FnolStatus,
  ReportsQuery,
} from 'src/types/graphql-types';
import { FaArrowRight } from 'react-icons/fa';
import { ColumnsType } from '@nirvana/ui-kit/src/components/table/table-v8';
import { StatusChip } from '../components/StatusChip';
import { formatDateAgo } from '../utils/formatDateAgo';

export const getClaimsColumns = () => {
  const columnHelper =
    createColumnHelper<ClaimsByDotNumberQuery['claimsByDOTNumber'][number]>();
  return [
    columnHelper.accessor('externalId', {
      header: 'Claim Number',
      enableSorting: false,

      cell: ({ row, getValue }) => {
        return (
          <div>
            <Tooltip
              title={
                <div className="w-36">
                  <div className="flex justify-between">
                    <p> Policy ID</p>
                    <p>{row.original.policyNumber}</p>
                  </div>
                </div>
              }
            >
              <div className="flex justify-between">
                <strong>{getValue()}</strong>
                <span className="ml-2 text-base text-primary-main">
                  <HiOutlineInformationCircle />
                </span>
              </div>
            </Tooltip>
          </div>
        );
      },
    }),
    columnHelper.accessor('status', {
      enableSorting: true,
      header: 'Status',
      sortingFn: (rowA, rowB) => {
        // Define the priority of statuses
        const statusPriority = {
          [ClaimStatus.Open]: 1,
          [ClaimStatus.Reopen]: 2,
          [ClaimStatus.Closed]: 3,
        } as { [key in ClaimStatus]: number };

        const statusA = rowA.original.status;
        const statusB = rowB.original.status;

        // Compare statuses based on their priority
        if (statusPriority[statusA] < statusPriority[statusB]) {
          return -1;
        }
        if (statusPriority[statusA] > statusPriority[statusB]) {
          return 1;
        }
        // If all else fails, sort by ID
        return rowA.original.externalId.localeCompare(rowB.original.externalId);
      },
      cell: ({ getValue }) => <StatusChip status={getValue()} />,
    }),
    columnHelper.accessor('lineOfBusiness', {
      header: 'Claim Type',
      enableSorting: false,
      cell: ({ getValue }) => {
        return <Chip color="tint" label={getValue()} />;
      },
    }),
    columnHelper.accessor('reportedBy', {
      enableSorting: true,
      header: 'Reported By',
      cell: ({ getValue }) => {
        return (
          <div className="max-w-48">
            <Tooltip title={<>{getValue()?.toUpperCase()}</>}>
              <p className="break-all truncate max-w-48">
                {getValue()?.toUpperCase()}
              </p>
            </Tooltip>
          </div>
        );
      },
    }),
    columnHelper.accessor('reportedAt', {
      enableSorting: true,
      header: 'Reported On',
      cell: ({ getValue }) => {
        return getFormattedDate(getValue(), 'MMM dd yyyy');
      },
    }),
    columnHelper.accessor('modifiedAt', {
      enableSorting: true,
      header: 'Last Action Taken',
      cell: ({ getValue }) => {
        return formatDateAgo(getValue());
      },
    }),
    columnHelper.display({
      enableSorting: false,
      id: 'view_details',
      header: '',
      cell: () => {
        return (
          <a className="cursor-pointer text-text-blue">
            View <FaArrowRight className="inline-block" />
          </a>
        );
      },
    }),
  ] as ColumnsType<ClaimsByDotNumberQuery['claimsByDOTNumber'][number]>;
};

export const getReportsColumns = () => {
  const columnHelper = createColumnHelper<ReportsQuery['fnols'][number]>();
  return [
    columnHelper.accessor('clientClaimNumber', {
      enableSorting: true,
      header: 'Reference Number',
    }),
    columnHelper.accessor('createdBy', {
      enableSorting: true,
      header: 'Reported By',
    }),
    columnHelper.accessor('createdAt', {
      enableSorting: true,
      header: 'Report Date',
      cell: ({ getValue }) => {
        return formatDateAgo(getValue());
      },
    }),
    columnHelper.accessor('status', {
      enableSorting: true,
      header: 'Filling Status',
      sortingFn: (rowA, rowB) => {
        // Define the priority of statuses
        const statusPriority = {
          [FnolStatus.Declined]: 1,
          [FnolStatus.Invalid]: 1,
          [FnolStatus.Processed]: 0,
          [FnolStatus.Sent]: 0,
        } as { [key in FnolStatus]: number };

        const statusA = rowA.original.status;
        const statusB = rowB.original.status;

        // Compare statuses based on their priority
        if (statusPriority[statusA] < statusPriority[statusB]) {
          return -1;
        }
        if (statusPriority[statusA] > statusPriority[statusB]) {
          return 1;
        }
        // If all else fails, sort by ID
        return rowA.original.id.localeCompare(rowB.original.id);
      },
      cell: ({ getValue }) => <StatusChip status={getValue()} />,
    }),
  ] as ColumnsType<ReportsQuery['fnols'][number]>;
};
