import { getFormattedDate, Show } from '@nirvana/ui-kit';
import { CircularProgress, IconButton } from '@material-ui/core';
import {
  MdThumbDownAlt,
  MdThumbUpAlt,
  MdThumbUpOffAlt,
  MdThumbDownOffAlt,
} from 'react-icons/md';
import useSnackbar from 'src/hooks/useSnackbar';
import { useUpsertClaimSummaryFeedbackMutation } from 'src/types/graphql-types';
import { snackBarOptions } from '../constants/snackbarConfig';

type TimelineItemProps = {
  title: string;
  date: Date;
  summary?: string;
  currentFeedback?: boolean | null;
  showFeedback?: boolean;
  itemId?: string;
};

const isBoolean = (val: TimelineItemProps['currentFeedback']) =>
  typeof val === 'boolean';

const ThumbsUpIcon = ({
  currentFeedback,
}: {
  currentFeedback: TimelineItemProps['currentFeedback'];
}) => {
  if (currentFeedback === true) {
    return <MdThumbUpAlt className="text-base" />;
  }
  return <MdThumbUpOffAlt className="text-base" />;
};

const ThumbsDownIcon = ({
  currentFeedback,
}: {
  currentFeedback: TimelineItemProps['currentFeedback'];
}) => {
  if (currentFeedback === false) {
    return <MdThumbDownAlt className="text-base" />;
  }
  return <MdThumbDownOffAlt className="text-base" />;
};

export const TimelineItem = ({
  title,
  date,
  summary,
  showFeedback,
  itemId,
  currentFeedback,
}: TimelineItemProps) => {
  const enqueueSnackbar = useSnackbar();
  const [upsertClaimSummaryFeedback, { loading: mutationLoading }] =
    useUpsertClaimSummaryFeedbackMutation();

  const setFeedback = async (feedback: boolean) => {
    upsertClaimSummaryFeedback({
      variables: {
        claimSummaryId: itemId!,
        rating: feedback ? 1 : -1,
      },
      onCompleted: () => {
        enqueueSnackbar(
          'Thank you for your feedback!',
          'We appreciate your feedback and will use it to improve our service.',
          snackBarOptions('success'),
        );
      },
      refetchQueries: ['ClaimById'],
    });
  };

  return (
    <li className="relative marker:text-text-hint">
      <div className="flex flex-col gap-2 py-2">
        <div className="flex items-center gap-2">
          <h4 className="text-secondary-main">{title}</h4>
          <p className="text-text-hint">
            {getFormattedDate(date.toISOString(), 'MMM dd yyyy')}
          </p>
        </div>

        <div className="absolute flex flex-col items-center h-full -left-4 l-">
          <div className="w-2 h-2 my-2 rounded-full bg-text-hint" />
          <div className="border-0 border-l-2 border-dashed border-text-disabled h-[calc(100%_-_3em)]" />
        </div>
        <Show when={summary}>
          <p className="mt-0 text-text-hint">{summary}</p>
        </Show>
        <Show when={showFeedback}>
          <Show
            when={!mutationLoading}
            fallback={<CircularProgress className="p-2" />}
          >
            <div className="flex -mt-2" data-testid="item-feedback">
              <IconButton
                disabled={isBoolean(currentFeedback)}
                onClick={() => setFeedback(true)}
                className="text-primary-main"
              >
                <ThumbsUpIcon currentFeedback={currentFeedback} />
              </IconButton>
              <IconButton
                disabled={isBoolean(currentFeedback)}
                onClick={() => setFeedback(false)}
                className="text-primary-main"
              >
                <ThumbsDownIcon currentFeedback={currentFeedback} />
              </IconButton>
            </div>
          </Show>
        </Show>
      </div>
    </li>
  );
};
